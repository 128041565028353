.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 55px 10px 55px;
  background: #93b3b5;
}

.header h1 {
  color: white;
  font-size: 60px;
  font-family: Playfair Display SC;
  font-weight: 500;
  line-height: 75px;
  letter-spacing: 0.2px;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .header {
    padding: 10px 40px;
  }

  .header h1 {
    font-size: 50px;
    line-height: 50px;
  }
}

.header a {
  text-decoration: none;
  color: black;
  font-size: 12px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 18px;
  letter-spacing: 1.5px;
  background-color: white;
  padding: 15px 22px;
  box-shadow: 5px 5px 5px #00000000;
  transition: 0.2s box-shadow;
}

.header a:hover {
  box-shadow: 5px 5px 5px #00000020;
  transition: 0.2s box-shadow;
}

.firstSection {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 30px 0 50px 0;
}

@media only screen and (max-width: 1000px) {
  .firstSection {
    flex-direction: column;
    width: 100%;
  }
}

.firstSection > span {
  max-width: 450px;
  width: 80%;
  display: flex;
  align-items: flex-start;
  margin: 0 40px;
  font-size: 20px;
  font-family: Georgia;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 15px;
  letter-spacing: 0.75px;
  text-align: left;
}

.firstSection > div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: calc(100% - 600px);
}

@media only screen and (max-width: 1000px) {
  .firstSection > span {
    max-width: none;
    width: auto;
  }

  .firstSection > div {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .firstSection > div {
    width: 80%;
    margin-top: 20px;
    flex-direction: column;
    justify-content: flex-start;
  }

  .firstSection > div > * {
    width: 100%;
    margin-bottom: 15px;
  }

  .firstSection > div > .horizontalLine {
    display: none;
  }
}

.descSection {
  display: flex;
  align-items: center;
  width: 200px;
}

.descSection span {
  font-size: 15px;
  font-family: Georgia;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 1.5px;
  text-align: left;
}

.greyCircle {
  min-width: 40px;
  min-height: 40px;
  border-radius: 30px;
  margin-right: 20px;
  background-color: rgb(200, 200, 200);
}

.horizontalLine {
  margin: 0 20px;
  height: 75px;
  width: 1px;
  background-color: grey;
}

.secondSection {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.secondSection > div:first-child {
  position: sticky;
  width: 600px;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  align-items: flex-start;
  position: -webkit-sticky;
  top: 0; /* required */
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}

@media only screen and (max-width: 1000px) {
  .secondSection > div:first-child {
    max-height: none;
    position: relative;
    height: auto;
    width: calc(100% - 20px);
    padding: 0 10px;
  }
}

.secondSection > div h5 {
  font-size: 16px;
  font-family: Georgia;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin: 30px 0;
}

.secondSection > div h5 + div {
  display: flex;
  flex-direction: column;
}

.facial {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.arrowCircle {
  padding: 8px 8px 5px 8px;
  margin-right: 15px;
  border-radius: 20px;
  background-color: #14666c36;
}

.arrowCircle span {
  font-size: 16px;
  color: black;
}

.facialText {
  padding: 10px 0;
  font-weight: bold;
  text-align: left;
  font-size: 17px;
  font-family: Georgia;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.divider {
  width: 350px;
  height: 0.5px;
  background-color: #00000030;
  margin: 5px 0;
}

.rightSectionFacials {
  width: 100%;
  background: rgba(20, 102, 108, 0.11);
  border-top-left-radius: 120px;
  padding: 70px 100px;
}

.rightSectionFacialsTemp {
  width: 100%;
  background: rgba(20, 102, 108, 0.11);
  border-top-left-radius: 120px;
  padding: 70px 100px;
}

.rightSectionFacials > * {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.rightSectionFacialsTemp > * {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1200px) {
  .rightSectionFacials > * {
    border-top-left-radius: 40px;
    padding: 0;
  }

  .rightSectionFacialsTemp > * {
    border-top-left-radius: 40px;
    padding: 0;
  }
}

.rightSectionFacialsTemp > * > div {
  display: flex;
  flex-direction: row;
  margin: 50px auto;
  justify-content: center;
  width: 100%;
}

.rightSectionFacials > * > div {
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
  width: calc(100% - min(70px, 5%));
}

.rightSectionFacials > * > div img {
  max-width: 450px;
  width: 45%;
  max-height: 400px;
  height: auto;
  object-fit: cover;
  border-radius: 30px;
}

@media only screen and (max-width: 700px) {
  .rightSectionFacials > * > div {
    flex-direction: column;
    margin-bottom: 0px;
    padding: 10px;
  }

  .rightSectionFacialsTemp > * > div {
    flex-direction: column;
    margin-bottom: 0px;
    padding: 10px;
  }

  .rightSectionFacials > * > div:last-child {
    margin-bottom: 100px;
  }

  .rightSectionFacialsTemp > * > div:last-child {
    margin: 50px 0;
  }

  .rightSectionFacials > * > div img {
    width: calc(100% - 60px);
    padding: 25px 30px 40px 30px;
  }

  .rightSectionFacialsTemp > * > div img {
    width: calc(100% - 60px);
    padding: 25px 30px 40px 30px;
  }
}

.textBox {
  display: flex;
  flex-direction: column;
  padding: 25px 30px 40px 30px;
  background-color: white;
  border-radius: 30px;
  max-width: 500px;
  width: 75%;
  align-self: flex-end;
}

.rightSectionFacialsTemp .textBox {
  max-width: none;
}

@media only screen and (max-width: 700px) {
  .textBox {
    width: calc(100% - 100px);
    margin: 0 auto;
  }
}

.textBox span {
  font-size: 20px;
  font-family: Georgia;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.5px;
  font-weight: bold;
  text-align: left;
}

.textBox h4 {
  font-size: 13px;
  font-family: Georgia;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.5px;
  text-align: left;
}

.textBox div {
  text-align: left;
}

@media only screen and (max-width: 700px) {
  .textBox span {
    font-size: 18px;
    line-height: 22px;
  }
}

.textBox > div > p {
  text-align: left;
  font-size: 15px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin-bottom: 40px;
}

.textBox > div > a {
  text-decoration: none;
  color: black;
  font-size: 12px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 18px;
  letter-spacing: 1.5px;
  background-color: white;
  padding: 10px 18px;
  border: 2px solid #5a7aa6;
  box-shadow: 5px 5px 5px #00000000;
  transition: 0.2s box-shadow;
  text-align: left;
}

.textBox > div > a:hover {
  box-shadow: 5px 5px 5px #00000020;
  transition: 0.2s box-shadow;
}
