.body {
  min-height: calc(100vh - 500px);
  max-width: 1000px;
  margin: 0 auto;
}

.body h5 {
  text-align: left;
  font-size: 18px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 44px;
  letter-spacing: 5.5px;
  margin-bottom: 0;
  padding: 0 min(50px, 10%);
  width: calc(100% - min(100px, 20%));
}

.body h1 {
  margin: 15px 0 75px 0;
  text-align: left;
  font-size: 45px;
  font-family: Playfair Display SC;
  font-weight: 700;
  text-transform: capitalize;
  padding: 0 min(50px, 10%);
  width: calc(100% - min(100px, 20%));
}

.location {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.location > div:first-child {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.location > div:first-child h3 {
  font-size: 22px;
  font-family: Georgia;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.4px;
  margin: 15px 0 5px 0;
}

.location > div:first-child span {
  font-size: 11px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 13px;
  letter-spacing: 2px;
}

.location > div:first-child p {
  font-size: 16px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.3px;
}

.location > div:first-child img {
  max-width: 500px;
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-top-left-radius: 300px;
  border-top-right-radius: 300px;
  margin: 0 auto;
}

.location > div:first-child div {
  background-color: #f9f9f9;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.arrowButton {
  width: 350px;
  height: 80px;
  background: #d8e6e7;
  border-radius: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  margin: 80px 0 0 30px;
}

@media only screen and (max-width: 1200px) {
  .arrowButton {
    position: absolute;
    visibility: hidden;
  }
}

.arrowButton > span {
  padding: 8px;
  margin-right: 20px;
  border-radius: 30px;
  border: 2px solid #5a7aa6;
  cursor: pointer;
}

.arrowButton p {
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 25px;
  letter-spacing: 3.5px;
}

.bottom {
  background-color: #d9d9d9;
  height: 400px;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  transform: translateY(-250px);
}

.bottom + div {
  height: 150px;
}
