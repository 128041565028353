.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;
  justify-content: space-between;
}


@media only screen and (max-width: 400px) {
  .vertNavbar > a > img {
    width: 240px;
  }
}

.vertNavbar {
  z-index: 2;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 10px 0;
  width: calc(100% - 20px);
  background-color: white;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 50px;
}

.links > * {
  margin-left: 25px;
}

.links > *:not(.dropdown) > a,
.links > *:last-child {
  color: black;
  letter-spacing: 1.5px;
  text-decoration: none;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  transition: color 1s;
}

.links > *:not(.dropdown):hover > a,
.links > *.opened > a {
  color: #14666c75;
  transition: color 0.5s;
}

.links > *:first-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-top: 1px;
}

.links > *:last-child {
  height: 40px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px #14666c solid;
}

.links > *:first-child > div > span {
  font-size: 16px;
  margin-top: 3px;
  color: grey;
  font-weight: 700;
  margin-left: 4px;
  transition: transform 0.5s;
  padding: 15px 0;
}

.links > *:first-child:hover > div > span,
.links > *.opened > div > span {
  transform: rotate(180deg);
  transition: transform 1s;
}

.dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 5;
  background-color: white;
  top: 55px;
  padding: 15px;
  border-radius: 10px;
  transform: translateX(-22px);
  box-shadow: 4px 4px 15px 5px rgba(2, 2, 2, 0.314);
}

.dropdown > * {
  text-align: left;
  text-decoration: none;
  color: black;
  letter-spacing: 0.75px;
  font-size: 13px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 20px;
  background-color: white;
  transition: background-color 0.2s;
}

.dropdown > *:hover {
  background-color: #14666c36;
  transition: background-color 0.2s;
}

.dropdown > *:last-child {
  margin-bottom: 0;
  cursor: pointer;
}

.vertNavbarBackground {
  top: 85px;
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.298);
}

.vertNavbarMenu {
  top: 73px;
  z-index: 2;
  position: fixed;
  background-color: white;
  width: calc(100% - 40px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vertNavbarMenu > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
}

.vertNavbarMenu > div > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.vertNavbarMenu > div > div > div > a,
.vertNavbarMenu > *:last-child {
  color: black;
  letter-spacing: 1px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 38px;
}

.vertNavbarMenu > *:last-child {
  font-size: 12px;
}

.vertNavbarMenu > div > div {
  margin-bottom: 20px;
}

.vertNavbarMenu > *:last-child {
  height: 40px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px #14666c solid;
}