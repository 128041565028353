.body {
  width: 100%;
  max-height: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.leftSection {
  display: flex;
  flex-direction: column;
  background-color: #7e7975;
  padding: 60px 80px 60px 80px;
  align-items: center;
  justify-content: space-evenly;
}

.leftSection > img {
  width: 250px;
}

.logoSection {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.logoSection > a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  background-color: #595552;
  margin-right: 10px;
  text-decoration: none;
}

.logoSection > a > i {
  color: #7e7975;
}

.copyrigth {
  color: white;
  font-size: 9px;
  letter-spacing: 0.5px;
  margin-top: 100px;
}

.rightSection {
  width: calc(100% - 610px);
  display: flex;
  flex-direction: row;
  background-color: #14666c9f;
  padding: 60px 100px;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  .leftSection {
    padding: 30px;
  }

  .rightSection {
    flex-direction: column;
    padding: 30px 50px;
    width: calc(100% - 410px);
  }
}

@media only screen and (max-width: 600px) {
  .rightSection {
    flex-direction: column;
    width: calc(100% - 100px);
  }

  .leftSection {
    width: calc(100% - 160px);
    padding: 80px;
  }
}

.rightSection > div {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-wrap: wrap;
}

.navSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 75px;
  color: white;
  align-items: flex-start;
  margin-right: 50px;
}

@media only screen and (max-width: 1000px) {
  .rightSection > div {
    height: auto;
  }

  .navSection {
    margin-right: 20px;
    margin-bottom: 25px;
    height: fit-content;
  }
}

.navSection > span {
  text-align: left;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 2px;
}

.navSection > span + div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navSection > span + div > a,
.navSection > span + div > span {
  font-size: 13px;
  font-weight: bold;
  color: white;
  text-decoration: none;
  margin-bottom: 15px;
  text-align: left;
}
