.topSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px 75px;
}

.topSectionWrapper {
  min-height: 467px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.topSectionWrapper img {
  width: calc(100% - 650px);
  max-height: 467px;
  object-fit: cover;
}

.gradient {
  right: 0;
  width: calc(100% - 650px);
  position: absolute;
  height: 467px;
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 12%,
    rgba(255, 255, 255, 0) 55%
  );
}

@media only screen and (max-width: 950px) {
  .topSectionWrapper img {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .topSection {
    padding: 30px 35px;
  }
}

.topSection h1 {
  font-size: 42px;
  font-family: Playfair Display SC;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 60px;
  letter-spacing: 0.1px;
  text-align: left;
  margin-bottom: 0px;
}

.topSection p {
  font-size: 18px;
  font-family: Georgia;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.2px;
  text-align: left;
}

.topSection a {
  text-decoration: none;
  color: black;
  font-size: 12px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 18px;
  letter-spacing: 1.5px;
  background-color: white;
  padding: 10px 18px;
  border: 2px solid #5a7aa6;
  box-shadow: 5px 5px 5px #00000000;
  transition: 0.2s box-shadow;
}

.topSection a:hover {
  box-shadow: 5px 5px 5px #00000020;
  transition: 0.2s box-shadow;
}

.secondSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 75px;
  background-color: #c4b8af3d;
}

@media only screen and (max-width: 600px) {
  .secondSection {
    padding: 30px 25px;
  }
}

.secondSection h2 {
  font-size: 25px;
  font-family: Playfair Display SC;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 5px;
}

.secondSection p {
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
  margin: 15px 30px 0 30px;
  max-width: 1000px;
}

.imageBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px 0;
  width: 100%;
}

.imageBox > * {
  min-width: 160px;
  flex-grow: 1;
  width: 31.5%;
  margin: 10px;
  height: 300px;
}

.imageBox img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.thirdSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 75px;
}

@media only screen and (max-width: 600px) {
  .thirdSection {
    padding: 30px 35px;
  }
}

.thirdSection h2 {
  font-size: 28px;
  font-family: Playfair Display SC;
  font-weight: 700;
  line-height: 40px;
  max-width: 100%;
  width: 780px;
  letter-spacing: 0.1px;
  margin-bottom: 0;
}

.thirdSection p {
  font-size: 13px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 3px;
}

.fourthSection {
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  background-color: #14666c1a;
}

@media only screen and (max-width: 600px) {
  .fourthSection {
    padding: 30px 0;
  }
}

.fourthSectionTop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 75px;
  align-items: flex-start;
  margin-bottom: 60px;
}

@media only screen and (max-width: 600px) {
  .fourthSectionTop {
    padding: 0 30px;
  }
}

.fourthSectionTop > *:first-child {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 180px;
  margin-right: 40px;
  flex-grow: 1;
}

.fourthSectionTop > *:first-child h2 {
  font-size: 40px;
  font-family: Playfair Display SC;
  text-align: left;
  font-weight: 400;
  line-height: 50px;
  margin: 0 0 50px 0;
  width: 430px;
}

@media only screen and (max-width: 1150px) {
  .fourthSectionTop > *:first-child {
    height: auto;
  }

  .fourthSectionTop > *:first-child h2 {
    display: flex;
    min-width: 250px;
    width: 100%;
    flex-grow: 1;
    margin-bottom: 20px;
  }
}

.arrows {
  display: flex;
  flex-direction: row;
}

.arrows > * {
  padding: 8px;
  border-radius: 30px;
  border: 2px solid #5a7aa6;
  cursor: pointer;
}

.arrows > *:first-child {
  margin-right: 20px;
}

.fourthSectionTop > *:nth-child(2) {
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 500px;
  justify-content: space-between;
}

.fourthSectionTop > *:nth-child(2) p {
  font-size: 16px;
  font-family: Poppins, sans-serif;
  text-align: left;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.2px;
  margin: 10px 0;
}

@media only screen and (max-width: 1150px) {
  .fourthSectionTop > *:nth-child(2) {
    margin-top: 30px;
    height: auto;
    width: 100%;
  }

  .fourthSectionTop > *:nth-child(2) p {
    margin-bottom: 30px;
  }
}

.fourthSectionTop > *:nth-child(2) a {
  text-decoration: none;
  color: black;
  font-size: 12px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 18px;
  letter-spacing: 1.5px;
  background-color: white;
  padding: 15px 0px;
  width: 250px;
  border: 2px solid #5a7aa6;
  box-shadow: 5px 5px 5px #00000000;
  transition: 0.2s box-shadow;
}

.fourthSectionTop > *:nth-child(2) a:hover {
  box-shadow: 5px 5px 5px #00000020;
  transition: 0.2s box-shadow;
}

.fourthSectionBottom {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.fourthSectionBottom > * {
  margin: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  align-items: flex-start;
}

.fourthSectionBottom > * img {
  height: 300px;
  width: 100%;
  border-radius: 30px;
  object-fit: cover;
}

.fourthSectionBottom > * h3 {
  font-size: 25px;
  font-family: Georgia;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0.1px;
  margin: 20px 0 0 0;
}

.fourthSectionBottom > * p {
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2px;
}

.fourthSectionBottom > * a {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.fourthSectionBottom > * a > *:first-child {
  color: black;
  font-size: 11px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 1.4px;
  margin-right: 10px;
}

.fourthSectionBottom > * a > *:last-child {
  font-size: 15px;
  background-color: #c4b8af3d;
  padding: 5px;
  border-radius: 10px;
}

.fourthSectionBottomWrapper {
  margin: 0 75px;
  width: calc(100% - 150px);
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .fourthSectionBottomWrapper {
    margin: 0 20px;
    width: calc(100% - 40px);
  }
}

.fifthSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding-top: 40px;
}

@media only screen and (max-width: 600px) {
  .fifthSection {
    padding-top: 30px;
  }
}

.fifthSection h4 {
  font-size: 12px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 25px;
  letter-spacing: 3px;
  margin: 0;
}

.fifthSection h1 {
  font-size: 37px;
  font-family: Georgia;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0.1px;
  margin: 15px 0 50px 0;
  padding: 0 75px;
}

.fifthSection h3 {
  font-size: 22px;
  font-family: Georgia;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.4px;
  margin: 15px 0 5px 0;
}

.fifthSection span {
  font-size: 11px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 13px;
  letter-spacing: 2px;
}

.fifthSection p {
  font-size: 16px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.3px;
}

.fifthSection img {
  width: 500px;
  height: 400px;
  object-fit: cover;
  border-top-left-radius: 300px;
  border-top-right-radius: 300px;
}

.fifthSection div {
  background-color: #f9f9f9;
  width: calc(100% - 150px);
  padding: 0 75px 100px 75px;
}

.sixthSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.sixthSection img {
  width: 38%;
  min-width: 250px;
  max-height: 850px;
  flex-grow: 1;
  object-fit: cover;
}

.sixthSection > *:nth-child(2) {
  flex-grow: 1;
  width: calc(62% - 110px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 60px 55px;
  background-color: rgba(20, 102, 108, 0.45);
}

@media only screen and (max-width: 600px) {
  .sixthSection > *:nth-child(2) {
    padding: 30px 45px;
  }
}

.sixthSection > *:nth-child(2) p {
  color: white;
  font-size: 12px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 3px;
  text-align: left;
}

.sixthSection > *:nth-child(2) h1 {
  color: white;
  font-size: 35px;
  font-family: Playfair Display SC;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0.1px;
  text-align: left;
  margin-bottom: 70px;
}

@media only screen and (max-width: 600px) {
  .sixthSection > *:nth-child(2) h1 {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 30px;
  }
}

.sixthSection > *:nth-child(2) > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sixthSection > *:nth-child(2) > div > * {
  background-color: white;
  padding: 13px;
  width: 42%;
  min-width: 200px;
  flex-grow: 1;
  height: auto;
  margin-bottom: 10px;
  margin: 10px;
  align-self: stretch;
  font-size: 10px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 1.5px;
  border-radius: 35px;
}

.seventhSection {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 50px 75px 30px 75px;
  width: calc(100% - 150px);
  background-color: #f5f5f5;
}

@media only screen and (max-width: 600px) {
  .seventhSection {
    padding: 30px 35px 20px 35px;
    width: calc(100% - 70px);
  }
}

.seventhSection h3 {
  font-size: 27px;
  font-family: Georgia;
  font-weight: 700;
  line-height: 40px;
  margin: 0;
}

.seventhSection p {
  font-size: 15px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
  width: 800px;
  max-width: 90%;
}

.procedures {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin: 50px 0;
}

.procedures > * {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .procedures {
    flex-direction: column;
  }

  .procedures > * {
    width: 100%;
  }
}

.procedures img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.procedures h4 {
  font-size: 16px;
  font-family: Georgia;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.4px;
  margin: 20px 0 0 0;
}

.procedures p {
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
}

.eigthSection {
  display: flex;
  flex-direction: column;
  padding: 40px 75px 120px 75px;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .eigthSection {
    padding: 30px 35px;
  }
}

.eigthSection h1 {
  font-size: 34px;
  font-family: Georgia;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0.1px;
  margin: 0 0 50px 0;
}

.opinions {
  display: flex;
  flex-direction: row;
}

.opinions > * {
  width: 20%;
  min-width: 210px;
  background-color: rgba(20, 102, 108, 0.13);
  padding: 20px;
  border-radius: 20px;
  margin-right: 40px;
}

.opinions > * div {
  color: #14666c96;
  padding: 10px 0;
}

.opinions > * p {
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
}

.ninthSection {
  padding: 60px 75px;
  background-color: #7e79751a;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ninthSection p {
  font-size: 15px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 25px;
  letter-spacing: 3px;
  margin: 0 0 40px 0;
}

.ninthSection > div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.ninthSection > div > * {
  mix-blend-mode: multiply;
  height: 80px;
  width: calc(18.4% - 15px);
  max-width: 300px;
  object-fit: contain;
}

.ninthSection > div > *:last-child {
  width: calc(8% - 15px);
}

@media only screen and (max-width: 600px) {
  .ninthSection {
    padding: 30px 35px;
  }

  .ninthSection p {
    margin: 0;
  }

  .ninthSection > div {
    flex-direction: column;
  }

  .ninthSection > div > *,
  .ninthSection > div > *:last-child {
    width: 80%;
    margin-top: 20px;
  }
}

.ninthSection > div > *:nth-child(4),
.ninthSection > div > *:nth-child(5) {
  opacity: 0.6;
}

.ninthSection > div > *:first-child {
  width: 140px;
}

.tenthSection {
  display: flex;
  flex-direction: row;
  width: 90%;
  max-width: 1300px;
  margin: 80px max(calc(50% - 650px), 5%);
}

.tenthSection h1 {
  font-size: 37px;
  font-family: Georgia;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0.1px;
  text-align: left;
  max-width: 800px;
}

.tenthSection p {
  font-size: 16px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-align: left;
  max-width: 800px;
}

.tenthSection img {
  min-width: 325px;
  height: 400px;
  margin-left: 80px;
}

@media only screen and (max-width: 600px) {
  .tenthSection {
    margin: 40px 35px;
    flex-direction: column;
  }

  .tenthSection img {
    margin-left: 0;
    height: auto;
  }
}

.opinionsCarousel {
  width: 100%;
  position: relative;
  height: auto;
}

.opinionsCarousel > *:first-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 80px);
  display: flex;
  justify-content: space-between;
}

.opinionsCarousel > *:first-child > span {
  font-size: 35px;
  cursor: pointer;
}
