.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

@media only screen and (max-width: 1000px) {
  .body {
    padding: 0 10px;
  }
}

.background {
  width: 100%;
  height: calc(100% - 490px);
  position: absolute;
  z-index: -1;
  background: linear-gradient(
    180deg,
    rgba(126, 121, 117, 0.83) 0%,
    rgba(126, 121, 117, 0.622) 20%,
    rgba(126, 121, 117, 0) 100%
  );
}

.body > h5:first-of-type {
  margin-top: 60px;
  color: white;
  font-size: 18px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  line-height: 42.5px;
  letter-spacing: 1.5px;
  margin-bottom: 0;
}

.body h1 {
  width: 85%;
  max-width: 1200px;
  color: white;
  font-size: 55px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: 1.5px;
  margin: 0;
}

@media only screen and (max-width: 1000px) {
  .body h1 {
    width: 90%;
    font-size: 35px;
    line-height: 50px;
  }
}

.firstSection {
  margin-bottom: 75px;
  width: 80%;
  display: flex;
  flex-direction: row;
  padding: 70px 10%;
  background-color: white;
}

.ownerImage {
  position: relative;
  display: flex;
  align-self: flex-start;
  z-index: 1;
  margin-right: 80px;
  /* border-radius: 40px; */
  height: 380px;
  width: 380px;
  object-fit: cover;
}

.description {
  width: calc(100% - 390px);
  min-width: 580px;
  text-align: left;
}

@media only screen and (max-width: 850px) {
  .body > h5:first-of-type {
    margin-top: 50px;
  }

  .firstSection {
    margin-top: 50px;
  }

  .ownerImage {
    max-width: 500px;
    height: 370px;
    width: 70%;
    margin: 0 auto;
    display: none;
  }

  .description {
    transform: none;
    padding: 15px 20px 15x 20px;
    width: 100%;
    border-radius: 40px;
    min-width: unset;
  }
}

.description h5 {
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 42.5px;
  letter-spacing: 1.2px;
  margin: 0;
}

.description h4 {
  font-size: 23px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.7px;
  margin-top: 15px;
}

@media only screen and (max-width: 850px) {
  .description h4 {
    font-size: 18px;
    line-height: 20px;
  }
}

.description p {
  font-size: 15px;
  font-family: Georgia;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.secondSection {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: end;
  width: 90%;
  max-width: 1200px;
  text-align: center;
  margin-bottom: 50px;
}

.secondSection > div h2 {
  color: white;
  font-size: 32px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1.5px;
}

@media only screen and (max-width: 850px) {
  .secondSection {
    transform: none;
    flex-direction: column;
  }

  .secondSection > div h2 {
    font-size: 25px;
    line-height: 30px;
  }
}

.secondSection > div p {
  color: white;
  font-size: 18px;
  font-family: Georgia;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  margin-bottom: 50px;
}

.secondSection img {
  width: 600px;
  height: 280px;
  margin-left: 80px;
}

@media only screen and (max-width: 850px) {
  .secondSection img {
    width: 100%;
    max-width: 600px;
    height: auto;
  }
}

.thirdSection {
  background: #d0e3e5;
  border-radius: 40px;
  padding: 50px 60px 150px 60px;
  width: 90%;
  max-width: 1200px;
}

.thirdSection h1 {
  color: black;
  font-size: 40px;
  font-family: Playfair Display SC;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  width: 100%;
  margin: 0 0 30px 0;
}

@media only screen and (max-width: 850px) {
  .thirdSection {
    transform: none;
  }

  .thirdSection h1 {
    font-size: 30px;
    line-height: 35px;
  }
}

.thirdSection p {
  font-size: 18px;
  font-family: Georgia;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.75px;
}

.fourthSection {
  width: 125%;
  max-width: 1700px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transform: translateY(-120px);
}

.fourthSection img {
  width: 30%;
  max-width: 500px;
  border-radius: 30px;
  object-fit: cover;
}

@media only screen and (max-width: 850px) {
  .fourthSection {
    width: 100%;
    transform: translateY(-120px);
    flex-direction: column;
  }

  .fourthSection img {
    width: 80%;
    margin: 0 auto 20px auto;
  }
}
