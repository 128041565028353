.topSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 50px max(40px, 7%) 0 max(40px, 7%);
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 0 auto;
}

.topSection > div {
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;
  flex-grow: 1;
}

.topSection > div > h5 {
  font-size: 18px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 35px;
  letter-spacing: 5.5px;
  margin: 0;
}

.topSection > div > h1 {
  font-size: 45px;
  flex-grow: 1;
  font-family: Playfair Display SC;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 80px;
  letter-spacing: 6.5px;
  margin: 20px 0;
}

@media only screen and (max-width: 600px) {
  .topSection > div > h1 {
    font-size: 40px;
    line-height: 60px;
  }
}

.topSection > p {
  width: 100%;
  min-width: 300px;
  text-align: left;
  flex-grow: 1;
  font-size: 18px;
  font-family: Georgia;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 1.5px;
}

@media only screen and (max-width: 1200px) {
  .topSection > p {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 750px) {
  .topSection > p {
    margin-left: 0px;
  }
}

.form {
  margin-top: 50px;
  background: rgba(20, 102, 108, 0.24);
  border-top-left-radius: 120px;
  border-top-right-radius: 120px;
  padding: 40px 0;
}

.form > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 85%;
  max-width: 1100px;
  margin: 0 auto;
}

.form > div.fields > * {
  display: flex;
  flex-direction: column;
  min-width: 270px;
  width: 30%;
  align-content: center;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1050px) {
  .form > div.fields > * {
    flex-grow: 1;
    margin: 0 20px;
  }

  .textArea {
    transform: translateX(20px);
    min-width: 300px;
  }
}

.form > div > * > span,
.textArea > span {
  text-align: left;
  font-size: 11.5px;
  letter-spacing: 5px;
  font-weight: bold;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  line-height: 25px;
}
.form > div > * > input {
  border-radius: 12px;
  height: 20px;
  width: calc(100% - 30px);
  margin-top: 10px;
  border: none;
  padding: 15px;
}

.textArea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 85%;
}

.textArea > textarea {
  border-radius: 20px;
  border: none;
  padding: 15px;
  width: 100%;
  min-height: 200px;
  resize: none;
  margin-bottom: 30px;
}

.terms > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

div.textArea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 85%;
}

@media only screen and (max-width: 1050px) {
  .textArea > textarea {
    width: calc(100% - 65px);
  }
}

.terms > *:last-child {
  letter-spacing: 2px;
  font-weight: bold;
  padding: 15px 60px;
  margin-right: 0;
  margin-left: auto;
  background-color: white;
  border: 2px solid #14666caa;
  cursor: pointer;
}

.terms > *:last-child:hover {
  box-shadow: 5px 5px 5px #00000016;
}
