.h1 {
  font-size: 42px;
  font-family: Playfair Display SC;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 60px;
  letter-spacing: 0.1px;
  text-align: left;
  margin-bottom: 0px;
}

.h4 {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.35px;
  word-spacing: 2.5px;
  text-align: left;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-family: Poppins;
  margin-bottom: 15px;
}

.p {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.35px;
  word-spacing: 2.5px;
  text-align: left;
  margin-bottom: 0px;
  font-family: Georgia;
  margin-top: 0;
}

.h2 {
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.1px;
  word-spacing: 1.5px;
  margin-bottom: 0px;
  font-family: Poppins;
}

.h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.1px;
  word-spacing: 1.5px;
  margin-bottom: 0px;
  font-family: Poppins;
  margin-bottom: 15px;
}

.h5 {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.35px;
  word-spacing: 2.5px;
  margin-bottom: 0px;
  font-family: Georgia;
  margin-top: 25px;
  margin-bottom: 50px;
}
